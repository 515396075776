export default function textSlugify(str: string): string {
  // Remove all non-word characters and convert to lowercase
  str = str.replace(/[^\w\s]/gi, "").toLowerCase();

  // Replace spaces with hyphens
  str = str.replace(/\s+/g, "-");

  // Remove any leading or trailing hyphens
  str = str.replace(/^-+|-+$/g, "");

  return str;
}
